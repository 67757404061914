import React, { ReactElement } from "react";

import PrintSpeed from "../icons/printSpeed";
import PaperCapacity from "../icons/paperCapacity";
import ScanningAbilities from "../icons/scanningAbilities";
import WirelessConnectivity from "../icons/wirelessConnectivity";
import Maintenance from "../icons/maintenance";
import Memory from "../icons/memory";

import styles from "./styles.module.scss";
import FeatureList from "@components/shared/featureList";

const whatLookList = [
    {
        icon: <PrintSpeed />,
    },
    {
        icon: <PaperCapacity />,
    },
    {
        icon: <ScanningAbilities />,
    },
    {
        icon: <WirelessConnectivity />,
    },
    {
        icon: <Maintenance />,
    },
    {
        icon: <Memory />,
    },
];

export default function BenefitsList(): ReactElement {
    return (
        <FeatureList
            featureList={whatLookList}
            mainTitle="Key Consideration for Choosing a Commercial Copying Machine"
            mainDescription="f you're in the market for a new copying machine for your business, there are important
            factors to keep in mind. Here's your essential checklist for selecting the right office copier:"
            classes={{
                mainSectionClasses:
                    "flex flex-col  max-w-7xl mx-auto px-5 mt-10 mb-32 lg:mt-20",
                mainTitleClasses: "text-2xl font-bold lg:text-4xl mb-2 lg:mb-3",
                innerSectionClasses:
                    "flex flex-wrap  mt-3  justify-center items-center md:flex-row",
                oneBoxClasses:
                    "md:w-1/6  w-1/2   flex items-center justify-center  ",
                iconClasses: ` children:w-[150px] md:children:w-[178px] ml-4 sm:ml-24 md:ml-0  ${styles["whatToLookIcon"]} `,
            }}
        />
    );
}
